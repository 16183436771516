import { useEffect, useState } from 'react';
import RegistrationStepper from "../../components/UI/registrationStepper/RegistrationStepper";
import RegistrationForm from "../../components/createAccount/registrationForm/RegistrationForm";
import stepsData from "../../core/static/createAccount/registrationSteps.json";
import styles from "./CreateAccount.module.scss";
import RegistrationResult from "../../components/createAccount/registrationResult/RegistrationResult";
import FailedRegistrationPopup from "../../components/UI/failedRegistrationPopup/FailedRegistrationPopup";
import WaitingRegistrationPopup from "../../components/UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import RegistrationOfferList from "../../components/createAccount/registrationOfferList/RegistrationOfferList";
import amplitude from "amplitude-js";
import strings from "../../assets/localization/strings";
import LiveClasses from "../../components/createAccount/liveClasses/LiveClasses";

const CreateAccount = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [stepName, setStepName] = useState('RegistrationForm');
    const [stepComponent, setStepComponent] = useState();
    const [closedFailedRegistrationPopup, setClosedFailedRegistrationPopup] = useState(true)
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)

    const domain = localStorage.getItem('domain')
    // const withLiveClasses = localStorage.getItem('liveClasses')
    const withLiveClasses = false
    const steps = withLiveClasses ?
        stepsData.filter((el) => el.component !== "Empty" ) :
        stepsData.filter((el) => el.component !== "LiveClasses" )

    useEffect(() => {
        setClosedFailedRegistrationPopup(true)
        setDisplayWaitingRegistrationPopup(false)
    }, [])

    const tryAgainAction = () => {
        setClosedFailedRegistrationPopup(true);
    }

    const nextStep = () => {
        setActiveStep((prev) => prev + 1);
    };

    const skipStep = () => {
        amplitude.getInstance().logEvent('button_onboarding_skip_tapped');
        nextStep();
    }

    useEffect(() => {
        const name = steps[activeStep-1].component
        if(name === "Empty") {
            nextStep()
            return
        }
        setStepName(name)
        setStepComponent(findStep(name))
    }, [activeStep])

    const findStep = (value) => {
        switch(value) {
            case "RegistrationForm":
                return <RegistrationForm nextStep={nextStep} setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} />;
            case "LiveClasses":
                return <LiveClasses nextStep={nextStep} />;
            case "RegistrationOfferList":
                return <RegistrationOfferList nextStep={nextStep} mealPlan={true} />;
            case "RegistrationResult":
                return <RegistrationResult />;
            default:
                return <RegistrationForm nextStep={nextStep} setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain}/>;
        }
    }
    const stepsWithSkip = ["LiveClasses", "RegistrationOfferList"]

    return (
        <div className={`${styles.mainContainer} flex-column container`}>
            { !closedFailedRegistrationPopup ? (<FailedRegistrationPopup onSubmit={tryAgainAction} />) : null }
            { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
            <div className={styles.imageContainer}>
                <img className={styles.logoImage} loading="lazy" src="/images/logo-black.svg" alt="" />
                {stepsWithSkip.indexOf(stepName) > -1 && (
                    <div className={`${styles.skipButton} flex-row`} onClick={skipStep}>
                        <span className={styles.buttonText}>{strings.registration.skipLink}</span>
                        <img className={styles.skipIcon} loading="lazy" src="/images/icons/gray-arrow-small.svg" alt="" />
                    </div>
                )}
            </div>
            <RegistrationStepper steps={steps} currentStep={activeStep} />
            {stepComponent}
        </div>
    )
}

export default CreateAccount;