import styles from "./StartTrial.module.scss";
import LazyImage from "../../lazyImage/LazyImage";

const StartTrial = (props) => {
    const { style } = props;

    const containerClass = `${styles.startTrialBox} ${styles[`${style}Campaign`]}`

    return (
        <div className={containerClass}>
            <div className={styles.startTrialContainer}>
                <div className={styles.startTrial}>
                    <h2>
                        Start your personal plan with
                        <br/>
                        <span className={styles.redText}>7-day trial</span>
                    </h2>
                    <div className={styles.giftImage}>
                        <LazyImage
                            srcWebp="/images/gift_icon.webp"
                            src="/images/gift_icon.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
      )
  }

export default StartTrial;