import { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

import "./Checkbox.scss";

const BpIcon = styled('span')(({ theme, isBig, withoutBg }) => ({
    borderRadius: '50%',
    position: 'relative',
    width: isBig ? 30 : 20,
    height: isBig ? 30 : 20,
    padding: 0,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: withoutBg ? 'unset' : '#FFFFFF',
    border: withoutBg ? "1px solid #FFFFFF" : 'unset',
    '.Mui-focusVisible &': {
      outline: '1px solid #BBBBBB',
    },
}));

const BpCheckedIcon = styled(BpIcon)(({ isBig, settings }) => ({
    backgroundColor: settings.backgroundColor,
    backgroundImage: settings.backgroundImage,
    boxShadow: settings.boxShadow,
    border: settings.border,
    '&:before': {
        position: 'absolute',
        top: '55%',
        left: '50%',
        width: isBig ? 16 : 10,
        height: isBig ? 16 : 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage: settings.beforeBackgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundSize: isBig ? '16px 16px' : '10px 8px',
        content: '""',
    },
}));

const checkedStyledIcon = (color) => {
    let settings = {
        backgroundColor: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
        backgroundImage: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
        boxShadow: '0px 0px 2px rgba(225, 87, 104, 0.7)',
        border: 'unset',
        beforeBackgroundImage: "url('/images/icons/check-icon.svg')"
    }
    switch(color) {
        case "pink":
            return settings
        case "yellowBf":
            return { ...settings,
                backgroundColor: '#FDE007',
                backgroundImage: '#FDE007',
                boxShadow: 'unset',
            }
        case "green":
            return { ...settings,
                backgroundColor: '#AFD6D5',
                backgroundImage: '#AFD6D5',
                border: '0.5px solid #2D603F',
                beforeBackgroundImage: "url('/images/icons/check-icon-black.svg')"
            }
        case "lightGreen":
            return { ...settings,
                backgroundColor: '#57A97B',
                backgroundImage: '#57A97B',
                boxShadow: 'unset'
            }
        case "salad":
            return { ...settings,
                backgroundColor: '#76FB4C',
                backgroundImage: '#76FB4C',
                boxShadow: 'unset',
                beforeBackgroundImage: "url('/images/icons/check-icon-black.svg')"
            }
        case "yellow":
            return { ...settings,
                backgroundColor: '#DAFF21',
                backgroundImage: '#DAFF21',
                boxShadow: 'unset',
                beforeBackgroundImage: "url('/images/icons/check-icon-black.svg')"
            }
        case "purple":
            return { ...settings,
                backgroundColor: '#7D8BF6',
                backgroundImage: '#7D8BF6',
                boxShadow: '0px 0px 2px rgba(202, 207, 247, 0.79)'
            }
        case "lightPurple":
            return { ...settings,
                backgroundColor: '#B826F6',
                backgroundImage: '#B826F6',
                boxShadow: '0px 0px 2px rgba(202, 207, 247, 0.79)',
            }
        default:
            return settings
    }
}

const CustomCheckbox = props => {
    const { data, checkCard, color, isBig, withoutBg } = props;
    const [card, setCard] = useState(data)

    useEffect(() => {
        setCard(data)
    }, [data])

    const checkedCard = (e) => {
        setCard({...card, checked: e.target.checked});
        checkCard({...card, checked: e.target.checked});
    }

    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disabled={card.disabled || false}
            disableRipple
            checked={card.checked}
            checkedIcon={<BpCheckedIcon isBig={isBig} withoutBg={withoutBg} settings={checkedStyledIcon(color)} />}
            icon={<BpIcon isBig={isBig} withoutBg={withoutBg} />}
            onChange={e => checkedCard(e)}
        />
    )
}

export default CustomCheckbox;