
import LazyImage from "../lazyImage/LazyImage";
import styles from "./LiveClassCard.module.scss";
import CustomCheckbox from "../checkbox/Checkbox";

const LiveClassCard = props => {
    const { cardData, activeCard, selectCard, small } = props;

    const clickCard = () => {
        selectCard(cardData);
    }

    const cardContainerClass = `${styles.cardContainer} ${small ? styles.smallCard : ''} ${activeCard ? styles.activeCard : ''} flex-column`

    return (
        <div className={cardContainerClass}
             onClick={clickCard}
        >
            <div className={styles.checkboxArea}>
                <CustomCheckbox
                    isBig={!small}
                    withoutBg={true}
                    data={{checked: activeCard}}
                    color={"pink"}
                    checkCard={()=>{}}
                />
            </div>
            <div className={styles.imageContainer}>
                <LazyImage
                    className={styles.cardIcon}
                    src={`/images/registration/liveClasses/${cardData.image}`}
                    alt=""
                />
            </div>
            <div className={`${styles.cardInfo} flex-column`}>
                <div className={`${styles.mainInfo} flex-column`}>
                    <div className={styles.liveImage}>
                        <LazyImage
                            className={styles.liveIcon}
                            src={`/images/registration/liveClasses/live.svg`}
                            alt=""
                        />
                    </div>
                    <div className={`${styles.mainInfoText} flex-column`}>
                        <div className={styles.timeInfo}>
                            <span>{cardData.timeInfo}</span>
                        </div>
                        <div className={styles.title}>
                            <span>{cardData.title}</span>
                        </div>
                        <div className={styles.teacher}>
                        <span>{cardData.teacher}</span>
                    </div>
                    </div>
                </div>
                <div className={`${styles.priceContainer} flex-column`}>
                    <div className={styles.duration}>
                        <span>{cardData.duration}</span>
                    </div>
                    <div className={`${styles.priceInfo} flex-row`}>
                        <div className={styles.oldPrice}>
                            ${cardData.oldPrice}
                            <span></span>
                        </div>
                        <div className={styles.newPrice}>
                            <span>${cardData.price}</span>
                            <span className={styles.priceComment}>
                                {cardData.priceComment}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default LiveClassCard;