import styles from "./VideoFeedbacks.module.scss";
import CircleVideoPlayer from "../circleVideoPlayer/CircleVideoPlayer";
import strings from "../../../assets/localization/strings";
import {useEffect, useRef, useState} from "react";

const mutedFeedbacks = {0: true, 1: true, 2: true, 3: true, 4: true}

const VideoFeedbacks = (props) => {
    const { themeData, feedbacks, view, title, name } = props;
    const [mutedVideos, setMutedVideos] = useState(mutedFeedbacks);

    const containerRef = useRef(null);

    useEffect(() => {
       if(feedbacks.length > 1){

        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            const screenHeight = window.innerHeight;
            const elementPosition = containerRef.current.offsetTop;
            if(scrollTop + screenHeight < elementPosition || scrollTop > elementPosition + 300) {
                setMutedVideos(mutedFeedbacks)
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

      }
      else{
      setMutedVideos(mutedFeedbacks)
      }
    }, []);

    const handleMuteAll = (key, value) => {
        setMutedVideos({...mutedFeedbacks, [key]: value })
    }

    const mainContainerClass = `${feedbacks.length > 1 ?
        `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]}` :
        styles.singleMainContainer} flex-column`

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            {title && <h2 className={styles.title}>
                {strings.paywall.usersMeetGoals}
            </h2>}

            { feedbacks.length > 1  ?
                <div className={`${styles.feedbacksContainer}`}>
                    <div className={`${styles.scrollableArea} flex-row`}>
                        {
                            feedbacks.map(feedback =>
                                <div key={feedback.id} className={styles.feedbackContainer}>
                                    <div className={styles.feedbackVideo}>
                                        <CircleVideoPlayer
                                            id={feedback.id}
                                            src={feedback.src}
                                            srcWebm={feedback.srcWebm}
                                            poster={feedback.poster}
                                            muteVideo={mutedVideos[feedback.id]}
                                            handleMuteAll={handleMuteAll}
                                            view={view} />
                                    </div>
                                   {name && <div className={styles.feedbackName} >
                                        {feedback.name}
                                    </div>}
                                </div>
                            )
                        }
                    </div>
                </div>
            :
                <div className={view === "rectangle" ? styles.feedbackLeftContainer : styles.feedbackCenterContainer}>
                    <CircleVideoPlayer
                        id={feedbacks[0].id}
                        src={feedbacks[0].src}
                        srcWebm={feedbacks[0].srcWebm}
                        poster={feedbacks[0].poster}
                        muteVideo={mutedVideos[feedbacks[0].id]}
                        handleMuteAll={handleMuteAll}
                        view={view} />
                </div>
            }
        </div>
    )
}

export default VideoFeedbacks
