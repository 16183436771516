import { useEffect, useState } from "react";

import WaitingRegistrationPopup from "../../UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import SuccessRegistrationPopup from "../../UI/successfulRegistrationPopup/SuccessRegistrationPopup";
import LiveClassCard from "../../UI/liveClassCard/LiveClassCard";

import amplitude from "amplitude-js";
import strings from "../../../assets/localization/strings.jsx";

import styles from "./LiveClasses.module.scss";

const LiveClasses = props => {
    const { nextStep } = props
    const textData = strings.registration.liveClasses
    const [cards, setCards] = useState(liveClassesSettings.cards);
    const [activeCards, setActiveCards] = useState([]);
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)
    const [displaySuccessRegistrationPopup, setDisplaySuccessRegistrationPopup] = useState(false)
    const [disableButton, setDisableButton] = useState(true);

    const paypalId = 'Af7bctxkCUaREhFJ0BemXCIe_wjt63BQZ6lAPH58TSx-ew4SMvdCAXT5vKgf0iiAPGmGGo9TGSnsRddE'
    const testPaypalId = 'AVMWUzb4KSB_QhCJ6MAB72V7RgeM4KVLPSDcqhDDi6HPfXLB70erhueUmevRgsY74_kXlO4_ucmI75pP'
    const [paypalClientId, setPaypalClientId] = useState( localStorage.getItem('liveMode') === "true" ? paypalId : testPaypalId);

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_live_classes_appeared');
    }, [])

    const selectCard = (card) => {
        const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
        if(removeCardIndex === -1){
            setActiveCards([...activeCards, card])
        } else {
            setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
        }
    }

    useEffect(() => {
        if(activeCards.length !== 0) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [activeCards])

    const paypalSubscribe = (data, actions) => {
        setDisplayWaitingRegistrationPopup(true)
        // let price = liveClassesSettings[offerVariant]["priceId"]["paypal"];
        // return actions.subscription.create({
        //     plan_id: localStorage.getItem('liveMode') === "true" ? price : "P-01W53846FU6608113M2QQS6A",
        // });
    };

    const paypalOnApprove = async (data, details) => {
        setDisplayWaitingRegistrationPopup(false)
        setDisplaySuccessRegistrationPopup(true)
        setTimeout(() => {
            setDisplaySuccessRegistrationPopup(false)
            nextStep();
        }, 3000);
    }

    const clickContinue = () => {
        setDisplayWaitingRegistrationPopup(true)
        const activeCardsTitle = activeCards.map(card => { return card.title})
        amplitude.getInstance().logEvent('button_onboarding_add_to_my_plan_live_classes_tapped', {answer: activeCardsTitle});

        setDisplayWaitingRegistrationPopup(false)
        setDisplaySuccessRegistrationPopup(true)
        setTimeout(() => {
            setDisplaySuccessRegistrationPopup(false)
            nextStep();
        }, 3000);

    }

    const confirmAction = async () => {
        setDisplayWaitingRegistrationPopup(true)
        amplitude.getInstance().logEvent('button_onboarding_add_to_my_plan_tapped');
    }

    return (
        <>
            { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
            { displaySuccessRegistrationPopup ? (<SuccessRegistrationPopup />) : null }

            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.title}>{textData.title}</h1>
                <div className={styles.subtitleBox}>
                    <span className={styles.subtitle}>{textData.subtitle}</span>
                    <span className={styles.subtitleBold}>{textData.subtitleBold}</span>
                </div>
                <div className={styles.formContainer}>
                    { cards.length === 1 ? (
                        <LiveClassCard
                            small={false}
                            cardData={cards[0]}
                            selectCard={selectCard}
                            activeCard={activeCards.indexOf(cards[0])!==-1}
                        />
                    ) : (
                        <div className={styles.cardsContainer}>
                            { cards?.map((card, idx) => (
                                <LiveClassCard
                                    small={true}
                                    key={idx}
                                    cardData={card}
                                    selectCard={selectCard}
                                    activeCard={activeCards.indexOf(card)!==-1}
                                />
                            ))}
                        </div>
                        )
                    }
                </div>
                <button
                    className={`${styles.buttonContainer} ${disableButton ? styles.disabledButton : ''}`}
                    onClick={clickContinue}
                    disabled={!!disableButton}
                >
                    {textData.continueButton}
                </button>
            </div>
        </>
    )
}

export default LiveClasses;

const liveClassesSettings = {
    cardsSingle: [
        {
            id: 1,
            image: "1.png",
            title: "Chair workout (ZOOM)",
            timeInfo: "Thursday, Oct 3 | 3:00 PM NYT",
            teacher: "with Alessa Choy",
            duration: "Beg. Lvl | 30-45 min",
            oldPrice: "20.00",
            price: "9.99",
            priceComment: "only!"
        },
    ],
    cards: [
        {
            id: 1,
            image: "1.png",
            title: "Chair workout (ZOOM)",
            timeInfo: "Thursday, Oct 3 | 3:00 PM NYT",
            teacher: "with Alessa Choy",
            duration: "Beg. Lvl | 30-45 min",
            oldPrice: "20.00",
            price: "9.99",
            priceComment: "only!"
        },
        {
            id: 2,
            image: "2.png",
            title: "Dance workout (ZOOM)",
            timeInfo: "Monday, Oct 7 | 3:00 PM NYT ",
            teacher: "with Armoni",
            duration: "Beg. Lvl | 30-45 min",
            oldPrice: "20.00",
            price: "9.99",
            priceComment: ""
        },
        {
            id: 3,
            image: "3.png",
            title: "Chair workout (ZOOM)",
            timeInfo: "Thursday, Oct 3 | 3:00 PM NYT",
            teacher: "with Alessa Choy",
            duration: "Beg. Lvl | 30-45 min",
            oldPrice: "20.00",
            price: "9.99",
            priceComment: ""
        },
        {
            id: 4,
            image: "4.png",
            title: "Chair workout (ZOOM)",
            timeInfo: "Thursday, Oct 3 | 3:00 PM NYT",
            teacher: "with Alessa Choy",
            duration: "Beg. Lvl | 30-45 min",
            oldPrice: "20.00",
            price: "9.99",
            priceComment: ""
        },
        {
            id: 5,
            image: "5.png",
            title: "Chair workout (ZOOM)",
            timeInfo: "Thursday, Oct 3 | 3:00 PM NYT",
            teacher: "with Alessa Choy",
            duration: "Beg. Lvl | 30-45 min",
            oldPrice: "20.00",
            price: "9.99",
            priceComment: ""
        }
    ]
}