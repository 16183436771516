import { useState, useEffect } from "react";

import amplitude from 'amplitude-js';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import WaitingRegistrationPopup from "../../UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import SuccessRegistrationPopup from "../../UI/successfulRegistrationPopup/SuccessRegistrationPopup";
import styles from "./RegistrationOfferList.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";
const RegistrationOfferList = props => {
    const { nextStep , liveMode, mealPlan} = props
    const textData = strings.registration.addOffer
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)
    const [displaySuccessRegistrationPopup, setDisplaySuccessRegistrationPopup] = useState(false)

    const [offerVariant, setOfferVariant] = useState("all");

    const paypalId = 'Af7bctxkCUaREhFJ0BemXCIe_wjt63BQZ6lAPH58TSx-ew4SMvdCAXT5vKgf0iiAPGmGGo9TGSnsRddE'
    const testPaypalId = 'AVMWUzb4KSB_QhCJ6MAB72V7RgeM4KVLPSDcqhDDi6HPfXLB70erhueUmevRgsY74_kXlO4_ucmI75pP'
    const [paypalClientId, setPaypalClientId] = useState( localStorage.getItem('liveMode') === "true" ? paypalId : testPaypalId);
    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_add_offer_appeared');
    }, [])

    const offerSettings = {

      "all": {
       "priceId": {
            "stripe": mealPlan ? "price_1Q4zpjJwa78Nc1FjJFDQoyuy" : "price_1Q2BU5Jwa78Nc1Fj6xSu16sX",
            "paypal": mealPlan ? "P-53749634Y6086573FM35ZZKQ" : "P-9P505249L00318602M3YWFNY"
           },
       "currentPriceValue": mealPlan ? "15.99" : "9.99",
       "oldPriceValue": mealPlan ? "31.98" : "20.00",
       "discount": mealPlan ? "50" : "75"
      },
    "pdf":{
    "priceId": {
       "stripe": mealPlan ? "price_1Q4konJwa78Nc1FjbQp721cM" : "price_1Q2BI7Jwa78Nc1FjOlkKaUTd",
       "paypal": mealPlan ? "P-20N34628ML561625NM35ZYSA" : "P-0CA951856W2786439M3YV7WI"
      },
    "currentPriceValue": mealPlan ? "9.99" : "7.99",
    "oldPriceValue": mealPlan ? "15.37" : "12.00",
     "discount": mealPlan ? "35" : "35"
    },
    "program": {
    "priceId":{
      "stripe": mealPlan ? "price_1Q4kjqJwa78Nc1FjnN8t0Y7a" : "price_1PoNzrJwa78Nc1FjiiqN0iGV",
      "paypal": mealPlan ? "P-43W86215NX570753PM35ZXOY" : "P-78872118MU622581EM27TLGA"
      },
    "currentPriceValue": mealPlan ? "9.99" : "7.99",
    "oldPriceValue": mealPlan ? "15.37" : "12.00",
    "discount": mealPlan ? "35" : "35"
    }


    };

    const paypalSubscribe = (data, actions) => {
        setDisplayWaitingRegistrationPopup(true)
        let price = offerSettings[offerVariant]["priceId"]["paypal"];
        return actions.subscription.create({
            plan_id: localStorage.getItem('liveMode') === "true" ? price : "P-01W53846FU6608113M2QQS6A",
        });
    };

    const paypalOnApprove = async (data, details) => {
        setDisplayWaitingRegistrationPopup(false)
        setDisplaySuccessRegistrationPopup(true)
        setTimeout(() => {
            setDisplaySuccessRegistrationPopup(false)
            nextStep();
        }, 3000);
    }

    const confirmAction = async () => {
        setDisplayWaitingRegistrationPopup(true)
        amplitude.getInstance().logEvent('button_onboarding_add_to_my_plan_tapped');
        if(localStorage.getItem('paymentMethodType') === "stripe"){
            let price = offerSettings[offerVariant]["priceId"]["stripe"];
            let purchaseResult = await fetch("https://1fqev002he.execute-api.us-east-1.amazonaws.com/dev/buy_program", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    priceId: price,
                    customerId: localStorage.getItem('customer_id'),

                    profileId: localStorage.getItem('profile_id'),
                    programId: offerVariant === 'pdf' ? null : 'ed-1721802472811',
                    specialOffer: offerVariant,
                    gift: mealPlan ? "meal_plan" : null,
                    paymentMethodId: localStorage.getItem('paymentMethodId'),
                    email: localStorage.getItem('emailInfo'),
                    amplitude_user_id: amplitude.getInstance().options.userId,
                    location: localStorage.getItem('search'),
                    onboarding_id: localStorage.getItem('onboarding_id'),
                    livemode: localStorage.getItem('liveMode'),
                }),
            }).then(async (response) => {
                console.log("createSubscription result");
                let resultBody = response.json();
                await resultBody.then( async (json) => {
                    console.log(json)

                    return json
                });
            });
            console.log("purchaseResult");
            console.log(purchaseResult);
            setDisplayWaitingRegistrationPopup(false)
            setDisplaySuccessRegistrationPopup(true)
            setTimeout(() => {
                setDisplaySuccessRegistrationPopup(false)
                nextStep();
            }, 3000);
        }
    }

    const skipStep = () => {
        amplitude.getInstance().logEvent('button_onboarding_skip_tapped');
        nextStep();
    }

    return (
    <>
             { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
             { displaySuccessRegistrationPopup ? (<SuccessRegistrationPopup />) : null }

        <div className={`${styles.mainContainer} flex-column`}>

            <h1 className={styles.title}>{textData.title}</h1>

            <div onClick={() => setOfferVariant("all")}
                 className={offerVariant === 'all'
                           ? (mealPlan ? styles.offerContainerActiveWithGift : styles.offerContainerActive)
                           : (mealPlan ? styles.offerContainerInactiveWithGift : styles.offerContainerInactive)}>
                 <LazyImage className={styles.offerIcon}
                                srcWebp={mealPlan ? "/images/registration/present_icon_gift.webp" : "/images/registration/present_icon.webp"}
                                src={mealPlan ? "/images/registration/present_icon_gift.png" : "/images/registration/present_icon.png"}
                                alt=""  />
                <div className={styles.contentContainer}>
                  <p className={`${styles.offerTitle}`} >
                   Healthy Tips + Fat-Burning Plan
                  </p>
                      <div className={styles.discountContainer}>
                           <span className={styles.cardSubtitle}>${offerSettings["all"]["oldPriceValue"]}</span>
                           <span className={styles.cardSubtitleOnly}>${offerSettings["all"]["currentPriceValue"]} only!</span>
                            <div className={styles.ceoDiscount}>{offerSettings["all"]["discount"]}% OFF</div>
                      </div>
                      {mealPlan && <p className={`${styles.offerTitle}`} >
                           🎉 Free bonus MEAL PLAN
                      </p>}
               </div>
            </div>


            <div onClick={() => setOfferVariant("pdf")} className={offerVariant === 'pdf' ? styles.offerContainerActive : styles.offerContainerInactive}>
                  <LazyImage className={styles.offerIcon}
                                            srcWebp="/images/registration/akilah_icon.webp"
                                            src="/images/registration/akilah_icon.png"
                                            alt=""  />
                  <div className={styles.contentContainer}>
                  <p className={`${styles.offerTitle}`} >
                               Healthy Tips Book form Akilah
                  </p>
                                  <div className={styles.discountContainer}>
                                       <span className={styles.cardSubtitle}>${offerSettings["pdf"]["oldPriceValue"]}</span>
                                       <span className={styles.cardSubtitleOnly}>${offerSettings["pdf"]["currentPriceValue"]} only!</span>
                                      <div className={styles.ceoDiscount}>{offerSettings["pdf"]["discount"]}% OFF</div>
                                  </div>
                  </div>
            </div>


            <div onClick={() => setOfferVariant("program")} className={offerVariant === 'program' ? styles.offerContainerActive : styles.offerContainerInactive}>
                 <LazyImage className={styles.offerIcon}
                                            srcWebp="/images/registration/dance_icon.webp"
                                            src="/images/registration/dance_icon.png"
                                            alt=""  />
                 <div className={styles.contentContainer}>
                 <p className={`${styles.offerTitle}`}>
                    Fat-Burning Home Workout Plan
                 </p>
                                  <div className={styles.discountContainer}>
                                       <span className={styles.cardSubtitle}>${offerSettings["program"]["oldPriceValue"]}</span>
                                       <span className={styles.cardSubtitleOnly}>${offerSettings["program"]["currentPriceValue"]} only!</span>
                                       <div className={styles.ceoDiscount}>{offerSettings["program"]["discount"]}% OFF</div>
                                  </div>
                 </div>
            </div>


            <div>
                             <div className={styles.infoContainer}>
                               <LazyImage className={styles.cardIcon}
                                          srcWebp="/images/icons/pink_icon_ok.webp"
                                          src="/images/icons/pink_icon_ok.png" alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                One-time payment
                               </p>
                             </div>
                             {(offerVariant === "pdf" || offerVariant === "all") &&
                             <div className={styles.infoContainer}>
                               <LazyImage className={styles.cardIcon}
                                          srcWebp="/images/icons/pink_icon_ok.webp"
                                          src="/images/icons/pink_icon_ok.png"
                                                            alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                 Unique tips from Akilah to get more value from chair workouts
                               </p>
                             </div>
                             }
                             {(offerVariant === "pdf" || offerVariant === "all") &&
                             <div className={styles.infoContainer}>
                             <LazyImage className={styles.cardIcon}
                                        srcWebp="/images/icons/pink_icon_ok.webp"
                                        src="/images/icons/pink_icon_ok.png"
                                                              alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                  Tips for Plus Size women
                               </p>
                             </div>
                             }
                             {(offerVariant === "program" || offerVariant === "all") &&
                             <div className={styles.infoContainer}>
                              <LazyImage className={styles.cardIcon}
                                         srcWebp="/images/icons/pink_icon_ok.webp"
                                         src="/images/icons/pink_icon_ok.png"
                                                                                           alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                  Special exercises to speed up your
                                  weight loss
                               </p>
                             </div>
                             }
                             {(offerVariant === "program" || offerVariant === "all") &&
                             <div className={styles.infoContainer}>
                              <LazyImage className={styles.cardIcon}
                                         src="/images/icons/pink_icon_ok.webp"
                                         src="/images/icons/pink_icon_ok.png"
                                                           alt=""  />
                               <p className={`${styles.cardTitle} cardTitle`}>
                                  Exclusive HIIT and dance workouts from CEO of Everdance app
                               </p>

                             </div>
                             }
                              {(offerVariant === "all" && mealPlan ) &&
                                                          <div className={styles.infoContainerGift}>
                                                           <LazyImage className={styles.cardIcon}
                                                                      srcWebp="/images/icons/pink_icon_ok.webp"
                                                                      src="/images/icons/pink_icon_ok.png"
                                                                                        alt=""  />
                                                            <p className={`${styles.cardTitle} cardTitle`}>
                                                              Free bonus MEAL PLAN
                                                            </p>

                                                          </div>
                              }

            </div>

            {
                        localStorage.getItem('paymentMethodType') === "stripe"
                        ?  <button className={styles.buttonContainer} onClick={confirmAction} >
                                         {textData.continueButton}
                                      </button>
                        :  <PayPalScriptProvider options={{ "client-id": paypalClientId, vault:true }}>
                                             <PayPalButtons
                                                 style={{ layout: "horizontal", width: 185, height: 48, borderRadius: 8 }}
                                                 createSubscription={(data, details) => paypalSubscribe(data, details)}
                                                 onApprove={(data, details) => paypalOnApprove(data, details)}
                                                 onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_paypal_tapped')}
                                             />
                                         </PayPalScriptProvider>
            }

        </div>
     </>
    )
}

export default RegistrationOfferList;