import styles from "./CircleVideoPlayer.module.scss";
import {useRef} from "react";

const CircleVideoPlayer = (props) => {
    const { id, src, srcWebm, muteVideo, handleMuteAll, poster, view } = props;
    const videoRef = useRef(null);

    const handleMuteUnmute = () => {
        handleMuteAll(id, !muteVideo)
        if (muteVideo) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    return (
        <div className={`${styles.container} ${styles[`${view}Container`]}`} >
            <video
                ref={videoRef}
                onLoadedData={e => e.target.style.display = 'block'}
                onCanPlay={e => e.target.play()}
                fullscreen="false"
                preload="auto"
                autoPlay
                loop
                playsInline
                controls={false}
                muted={muteVideo}
                className={styles.videoContainer}
            >

                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <button
                className={styles.muteButton}
                onClick={handleMuteUnmute}
            >
                {muteVideo ? (
                    <img className={styles.image} src="/images/video/muted.svg" alt="" />
                ) : (
                    <img className={styles.image} src="/images/video/unmuted.svg" alt="" />
                )}
            </button>
        </div>
    )
  }

export default CircleVideoPlayer;
